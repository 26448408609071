import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/menu';
import { graphql } from 'gatsby';
import ScaledTaglist from '../components/scaledtaglist';

const TagsPage = ({ data }) => {
  const tagsAndCount = data.allPrismicRecipe.group;

  return (
    <Layout>
      <SEO title="O stronie" />
      <header>
        <Menu></Menu>
      </header>
      <section className="page-content">
        <h1 className="section-header">Tagi</h1>
        <ScaledTaglist tagsAndCount={tagsAndCount} />
      </section>
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query AllTags {
    allPrismicRecipe {
      group(field: tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`;
