import React from 'react';
import { Link } from 'gatsby';
import { maxBy, reverse, sortBy } from 'lodash';

import './taglist.less';

const MAX_SIZE = 1.5;
const MIN_SIZE = 1;
const RANGE = MAX_SIZE - MIN_SIZE;

const renderTag = (tagAndValue) => {
  const { tag, weight } = tagAndValue;
  const tagLink = `/tagi/${tag}`;
  const tagName =  `#${tag}`;
  const tagWeight = `${MIN_SIZE + weight}rem`;
  return (
    <li key={tag}>
      <Link to={tagLink} style={{
        fontSize: tagWeight,
      }}>{tagName}</Link>
    </li>
  );
};

const ScaledTaglist = ({ tagsAndCount }) => {
  const sortedTags = reverse(sortBy(tagsAndCount, 'totalCount'));
  const maxCount = maxBy(tagsAndCount, 'totalCount').totalCount;
  const sortedAndWeightedTags = sortedTags.map((tag) => {
    const weight = ((tag.totalCount - 1 )/ (maxCount - 1)) * RANGE;
    return Object.assign({}, tag,  {
      weight, 
    });
  });


  return (
    <section id="taglist">
      <ul>
        {sortedAndWeightedTags.map(renderTag)}
      </ul>
    </section>
  );
};

export default ScaledTaglist;
